.progressActive {
    position: relative;
    z-index: 1;
    // color: #fff;

    // &::after {
    //     position: absolute;
    //     content: '';
    //     background-color: #16cd16;
    //     width: 120%;
    //     height: 100%;
    //     border-radius: 8px;
    //     top: 0;
    //     left: -10%;
    //     z-index: -1;
    // }
}


.column-kanban-new-design {
    margin-bottom: 2rem !important;
}

.column-kanban-height-design {
    // padding-right: 8px;
    height: 100%;
    // max-height: calc(100vh - 370px);
    // overflow-y: auto;
}

// @media screen and (max-height: 800px) {
//     .column-kanban-height-design {
//         max-height: calc(100vh - 330px);
//     }
// }

// @media screen and (max-height: 400px) {
//     .column-kanban-height-design {
//         max-height: calc(100vh - 200px);
//     }
// }