@charset "UTF-8";

// 1. Configuration and helpers
// @import
//   'abstracts/variables',
//   'abstracts/functions',
//   'abstracts/mixins';

// 2. Vendors
// @import
//   'vendors/normalize';

// 3. Base stuff
// @import
//   'base/base',
//   'base/fonts',
//   'base/typography',
//   'base/helpers';

// 4. Layout-related sections
@import 'layout/header', 'layout/footer';

// 5. Components
@import 'components/button', './components/label', './components/popUpStyle', './components/newStyle';

// 6. Page-specific styles
@import 'pages/login', 'pages/_resetPassword.scss', 'pages/register', 'pages/verifyCode', 'pages/_pricing.scss',
  'pages/error', './pages/employeeProfileCard';

// 7. Themes
@import 'themes/default';
@import 'bourbon';
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Asap:wght@500&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100&family=Varela+Round&display=swap');

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
}
header {
  height: 92px !important;
}

.progresscard_text {
  background-color: #fff;
  width: 100%;
  position: fixed;
  z-index: 9;
  margin-left: 10px;
}
::-webkit-calendar-picker-indicator {
  filter: invert(0%) sepia(0%) saturate(0%) hue-rotate(356deg) brightness(0%) contrast(0%);
}
.padding0 {
  padding-left: 0px !important;
}
/* width */


/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #ececec;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #d4d4d4;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #bcbbbb;
}

.break_btn-section {
  padding-top: 0px !important;
}
// .user_availability{
//   background-color: #FFF !important;
// }
.availability_text {
  margin-top: 16px !important;
}
.usefull_btn:hover {
  background-color: transparent !important;
}
.notification_sidebar {
  // .MuiPaper-root.MuiPaper-elevation{
  //   left: 75% !important;
  //   top:10px !important;
  // }
}
.break_active_btn {
  background-color: #002bff !important;
  color: #fff !important;
}
.availability_tab {
  .MuiTabs-flexContainer {
    button {
      font-size: 18px;
    }
    .MuiTab-root.MuiTab-textColorPrimary.Mui-selected {
      color: #000;
    }
    .dark_mode_text.MuiTab-root.MuiTab-textColorPrimary.Mui-selected{
      color: #FFF;
    }
  }
  .MuiTabs-indicator {
    height: 3px;
  }
}
.performance_dashboard {
  .MuiContainer-root {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}
.skeleton_table {
  th {
    background-color: transparent;
  }
}
@media screen and (max-width: 600px) {
  .notification_sidebar {
    .MuiPaper-root.MuiPaper-elevation {
      left: 10% !important;
      top: 10px !important;
    }
  }
  .availability_tab {
    .MuiTabs-flexContainer {
      button {
        font-size: 16px;
        margin-right: 20px !important;
        min-width: 78px !important;
      }
    }
  }
  /* width */
 

  /* Track */
  ::-webkit-scrollbar-track {
    border-radius: 4px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
  }
}

.form-container {
  // width: 80vw;
  // height: 100vh;
  // background-color: #3be446;
  display: flex;
  justify-content: center;
  align-items: center;
}

.upload-files-container {
  background-color: #f7fff7;
  width: 400px;
  margin-right: 30px;
  padding: 30px 60px;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 10px 20px, rgba(0, 0, 0, 0.28) 0px 6px 6px;
}

.drag-file-area {
  border: 2px dashed #7b2cbf;
  border-radius: 40px;
  margin: 10px 0 15px;
  padding: 30px 50px;
  width: 350px;
  text-align: center;
}

.drag-file-area .upload-icon {
  font-size: 50px;
}

.drag-file-area h3 {
  font-size: 26px;
  margin: 15px 0;
}

.drag-file-area label {
  font-size: 19px;
}

.drag-file-area label .browse-files-text {
  color: #7b2cbf;
  font-weight: bolder;
  cursor: pointer;
}

.browse-files span {
  position: relative;
  top: -25px;
}

.default-file-input {
  opacity: 0;
}

.cannot-upload-message {
  background-color: #ffc6c4;
  font-size: 17px;
  display: flex;
  align-items: center;
  margin: 5px 0;
  padding: 5px 10px 5px 30px;
  border-radius: 5px;
  color: #bb0000;
  display: none;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.cannot-upload-message span,
.upload-button-icon {
  padding-right: 10px;
}

.cannot-upload-message span:last-child {
  padding-left: 20px;
  cursor: pointer;
}

.file-block {
  color: #f7fff7;
  background-color: #7b2cbf;
  transition: all 1s;
  width: 390px;
  position: relative;
  display: none;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0 15px;
  padding: 10px 20px;
  border-radius: 25px;
  cursor: pointer;
}

.file-info {
  display: flex;
  align-items: center;
  font-size: 15px;
}

.file-icon {
  margin-right: 10px;
}

.file-name,
.file-size {
  padding: 0 3px;
}

.remove-file-icon {
  cursor: pointer;
}

.progress-bar {
  display: flex;
  position: absolute;
  bottom: 0;
  left: 4.5%;
  width: 0;
  height: 5px;
  border-radius: 25px;
  background-color: #4bb543;
}

.upload-button {
  font-family: 'Montserrat';
  background-color: #7b2cbf;
  color: #f7fff7;
  display: flex;
  align-items: center;
  font-size: 18px;
  border: none;
  border-radius: 20px;
  margin: 10px;
  padding: 7.5px 50px;
  cursor: pointer;
}

//calender css
.fc-daygrid-event.fc-daygrid-block-event.fc-h-event.fc-event.fc-event-start.fc-event-end {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.css-dbk7ja-MuiTableCell-root {
  width: 93px;
}

//main section css
.section-style {
  max-width: 32.22vw !important;
}

.read-morebtn {
  border: none;
  background: transparent;
  cursor: pointer;
  // color: #229a16;
}

.time_text {
  font-size: 1.5rem;
  line-height: 2.5rem;
  margin-right: 1rem;
}

.container-style {
  max-width: 33.33vw !important;
}

// end main section css
.graphdots {
  align-items: center !important;
}

// all heading css
.MuiTypography-root.main_heading {
  font-size: 2.222vw;
  line-height: 3.333vw;
  font-weight: 700;
}

.MuiTypography-root.heading_24 {
  font-size: 1.66vw;
  line-height: 2.5vw;
  font-weight: 700;
}

.brundow {
  min-width: 310 px;
  height: 400px;
  margin: 0 auto;
}

.MuiTypography-root.heading_16 {
  font-size: 1.11vw;
  line-height: 1.66vw;
  font-weight: 400;
}

.logList {
  font-size: 1.25rem !important;
  width: max-content;
  font-weight: 800 !important;
}

.MuiTypography-root.heading_14 {
  font-size: 0.9722vw;
  line-height: 1.527vw;
}

.MuiTypography-root.heading_12 {
  font-size: 0.833vw;
  line-height: 1.25vw;
}

// all heading css end

// placeholder css

::-webkit-input-placeholder {
  /* Edge */
  font-size: 1rem;
  line-height: 1.2rem;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-size: 1rem;
  line-height: 1.2rem;
}

::placeholder {
  font-size: 1rem;
  line-height: 1.2rem;
}

.MuiInputBase-root.MuiInputBase-formControl.MuiInputBase-adornedStart {
  // placeholder css

  ::-webkit-input-placeholder {
    /* Edge */
    font-size: 1rem;
    line-height: 1.2rem;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    font-size: 1rem;
    line-height: 1.2rem;
  }

  ::placeholder {
    font-size: 1rem;
    line-height: 1.2rem;
  }
}

.alloted-time {
  display: flex;
  align-items: center;
}

//placeholder css end

//social login code
.social-box {
  // display: inline-block;
  border: 1px solid rgba(145, 158, 171, 0.32);
  border-radius: 8px;
  padding: 0.85vw 0vw;
  margin-right: 0.694vw;
  align-items: center;
  display: flex;
  justify-content: center;
}

.social_icon {
  width: 1.66vw !important;
  height: 1.66vw !important;
  object-fit: contain !important;
}

.social-section {
  margin-top: 2.778vw;
  margin-bottom: 2vw;
}

.logo-img {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover
}

.only_social_section {
  fieldset {
    border: 1px solid transparent;
    border-top-color: rgba(145, 158, 171, 0.24);
    box-sizing: border-box;
    grid-area: 1 / 1;
    width: inherit;
    margin-bottom: 2vw;
  }

  fieldset:nth-of-type(2) {
    transform: rotate(90deg);
  }

  legend {
    margin: auto;
    padding: 0 0.64vw;
    text-align: center;
    font-size: 0.9722vw;
    line-height: 1.527vw;
    font-weight: 600;
    color: #637381;
  }
}

.card_height {
  height: 20vw;
}

//social login code end

@media only screen and (max-width: 899px) {
  .scroll_new_header {
    .MuiToolbar-root {
      overflow: auto;
    }
  

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #c4cdd5;
    border-radius: 10px;
    background: #c4cdd5;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #919eab;
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #919eab;
  }
  }
  .section_style {
    max-width: 95% !important;
    margin: auto;
  }

  .marginbtm {
    margin-bottom: 10px;

    .MuiFormControl-root {
      margin-bottom: 10px !important;
    }
  }

  .container-style {
    max-width: 98% !important;
    margin: auto;
  }

  // all heading css
  .MuiTypography-root.main_heading {
    font-size: 24px;
    line-height: 36px;
  }

  .MuiTypography-root.heading_24 {
    font-size: 24px;
    line-height: 32px;
  }

  .MuiTypography-root.heading_16 {
    font-size: 16px;
    line-height: 24px;
  }

  .MuiTypography-root.heading_14 {
    font-size: 14px;
    line-height: 22px;
  }

  .MuiTypography-root.heading_12 {
    font-size: 12px;
    line-height: 18px;
  }

  // placeholder css

  ::-webkit-input-placeholder {
    /* Edge */
    font-size: 16px;
    line-height: 24px;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    font-size: 16px;
    line-height: 24px;
  }

  ::placeholder {
    font-size: 16px;
    line-height: 24px;
  }

  //placeholder css end

  //social login code
  .social_icon {
    width: 24px !important;
    height: 24px !important;
    object-fit: scale-down !important;
  }

  .social-box {
    margin-right: 10px;
    padding: 8px 0;
  }

  .social-section {
    margin-top: 40px;
    margin-bottom: 34px;
  }

  .only_social_section {
    fieldset {
      margin-bottom: 34px;
    }

    legend {
      font-size: 14px;
      line-height: 22px;
    }
  }

  //social login code end

 

  .card_height {
    height: 250px;
  }
}

.dashboard {
  .slick-slider .MuiBox-root {
    right: 24px;
    bottom: 24px;
    align-items: end;
    justify-content: end;
    top: 0;
    left: 0;
  }
}

.timeBTn {
  color: #212b36 !important;
  background-color: transparent !important;
  padding-left: 0 !important;
  font-weight: 400 !important;
}

// .rbc-event-label {
//   display: none;
// }
// .rbc-agenda-time-cell{
//   display: none !important;
// }
// th.rbc-header.time {
//     width: 0px;
//     display: none !important;
// }

.fc .fc-list-sticky .fc-list-day > * {
  position: sticky;
  top: 0;
  background: #fff;
  background: var(--fc-page-bg-color, #3788d8) !important;
}

.fc .fc-list-event:hover td {
  background-color: #fff !important;
  background-color: transparent !important;
}

.Filter {
  float: right !important;
  cursor: pointer;
}

.main_container {
  clear: both !important;
}

.dropdown_container {
  max-width: 100% !important;

  .MuiFormControl-root.MuiTextField-root {
    display: flex;
  }
}

.stack-center {
  align-items: center !important;
}

/* CSS */
.button-85 {
  padding: 0.6em 2em;
  border: none;
  outline: none;
  color: rgb(255, 255, 255);
  // background: rgb(218, 120, 220);
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-85:before {
  content: '';
  background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  -webkit-filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing-button-85 20s linear infinite;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}

@keyframes glowing-button-85 {
  0% {
    background-position: 0 0;
  }

  50% {
    background-position: 400% 0;
  }

  100% {
    background-position: 0 0;
  }
}

.button-85:after {
  z-index: -1;
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: #222;
  left: 0;
  top: 0;
  border-radius: 10px;
}

.fc-event-time,
.fc-event-title {
  padding: 0 1px;
  white-space: nowrap;
}
.mom_margin {
  margin-bottom: 15px;
}
.task-card-section {
  margin-top: 10px;
  .task-card {
    display: inline-block;
    width: 31%;
    margin: 1% 1%;
    vertical-align: top;
  }
  .progresscard {
    margin: 9% 1% 1%;
  }
  .card-heading {
    cursor: pointer;
    text-transform: initial;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  .new-card-heading {
    text-transform: initial;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    line-clamp: 4;
    -webkit-box-orient: vertical;
  }

  /* width */
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* width */
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #c4cdd5;
    border-radius: 10px;
    background: #c4cdd5;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #919eab;
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #919eab;
  }
}

.sidebar-inputfield {
  .MuiAutocomplete-root.MuiAutocomplete-hasClearIcon {
    .MuiFormControl-root.MuiFormControl-fullWidth.MuiTextField-root {
      max-height: 200px;
      overflow-y: auto;
    }
  }
}

.mentions__suggestions__list {
  width: 100%;
  height: 100px;
  max-height: 100%;
  overflow-y: auto;
  max-width: 100%;

  textarea {
    border-radius: 5px;
    border: 1px solid #d4d4d4;
    outline: none;
    padding: 10px;
    word-break: break-all;
    overflow: auto !important;
  }
}

.edit-input-pop-up {
  textarea {
    border-radius: 5px;
    border: 1px solid #d4d4d4;
    outline: none;
    padding: 10px;
    word-break: break-all;
    overflow: auto !important;
  }
}

.working-hour-dialogbox {
  .MuiButtonBase-root,
  .cancle_btn-team {
    box-shadow: none !important;
    padding: 6px 16px;
    border-radius: 8px;
  }

  .MuiButtonBase-root {
    margin-left: 0;
  }

  .MuiPaper-root.MuiDialog-paper {
    max-width: 501px;
  }

  .MuiDialogTitle-root {
    font-size: 1rem;
    padding: 12px 16px;
  }

  .MuiDialogContent-root {
    padding: 12px 16px 0;
  }

  .working-hour-inner {
    background: #f4f6f8;
    border-radius: 0.625rem 0.625rem 0.425rem;
    padding: 0.7375rem 0rem 0.7375rem 1.2rem;
    margin-top: 0.85rem;
    margin-right: 5px;

    .MuiTypography-root {
      color: #212b36;
    }
  }

  .working-hour-inner-dark {
    background: #23272a;

    .MuiTypography-root {
      color: #ffffff;
    }
  }

  .MuiButtonBase-root.MuiCheckbox-root.MuiCheckbox-colorPrimary {
    padding: 9px 9px 3px;
  }

  .weekend {
    .MuiFormControlLabel-label {
      font-size: 1rem;
      line-height: 1.5rem;
    }
  }

  .manually-text {
    font-weight: 700;
    color: #454f5b;
  }

  .ambtn,
  .pmbtn {
    padding: 0.1rem 1.1rem;
    border: none;
    font-size: 0.875rem;
    line-height: 1.5rem;
    font-weight: 700;
    border-radius: 0.69rem;
    cursor: pointer;
  }

  .activetime {
    background-color: #00ab55;
    color: #ffffff;
    z-index: 99;
    margin-left: -12px;
  }

  .disabletime {
    color: #454f5b;
    background-color: #dce0e4;
    margin-left: -12px;
  }

  .time-inner-box {
    padding: 0.875rem 1rem;
    border: 1px solid rgba(145, 158, 171, 0.32);
    border-radius: 8px;
    display: flex;
    align-items: center;

    .MuiInputBase-input.MuiOutlinedInput-input {
      padding: 0;
      border: none;
    }

    .MuiOutlinedInput-notchedOutline {
      border-color: transparent !important;
    }

    .MuiInputBase-root.MuiOutlinedInput-root:hover {
      border-color: transparent !important;
    }
  }
}

.report-date-picker {
  align-items: center;

  .range-date-picker {
    border: 1px solid rgba(145, 158, 171, 0.32);
    border-radius: 8px;
    padding: 8px 10px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  :focus-visible {
    outline: #00ab55 auto 1px;
  }

  ::-webkit-input-placeholder {
    /* Edge */
    font-style: italic;
    font-weight: 400;
    color: #919eab;
    text-transform: uppercase;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    font-style: italic;
    font-weight: 400;
    color: #919eab;
    text-transform: uppercase;
  }

  ::placeholder {
    font-style: italic;
    font-weight: 400;
    color: #919eab;
    text-transform: uppercase;
  }
}

.react-datepicker__day.react-datepicker__day--selected {
  background-color: #00ab55 !important;
  border-radius: 50%;
}

.react-datepicker__day:hover {
  border-radius: 50% !important;
}

.react-datepicker__day.react-datepicker__day--in-range {
  background-color: rgba(0, 171, 85, 0.4) !important;
  border-radius: 50%;
}

.react-datepicker__day.react-datepicker__day--selected
  .react-datepicker__day--range-start.react-datepicker__day--in-range {
  background-color: #00ab55 !important;
}

.react-datepicker__day.react-datepicker__day--keyboard-selected {
  border-radius: 50% !important;
  background-color: #f0f0f0 !important;
  border: 1px solid #ccc;
}

.MuiPickersDateRangePickerInput-rangeInputsContainer {
  .MuiOutlinedInput-input {
    padding: 12.5px 14px;
    font-weight: 400;
    border-radius: 8px;
  }
}

.rdrSelected,
.rdrStartEdge,
.rdrEndEdge {
  color: #00ab55 !important;
}

.rdrInRange {
  color: rgba(0, 171, 85, 0.4) !important;
}

.rdrDayToday {
  .rdrSelected,
  .rdrStartEdge {
    // color: #fff !important;
    // border: 1px solid rgba(0, 0, 0, 0.6);
  }

  .rdrDayNumber span {
    // color: #000 !important;
  }

  .rdrEndEdge {
    color: #00ab55 !important;
  }
}

.rdrDayToday .rdrDayNumber span:after {
  background: #00ab55 !important;
}

.rdrMonth,
.rdrCalendarWrapper {
  width: 100% !important;
}

.rdrDayHovered {
  .rdrDayStartPreview,
  .rdrDayEndPreview {
    border: 1px solid #00ab55;
  }

  .rdrDayInPreview {
    border: 1px solid #00ab55;
  }
}

// .rdrEndEdge {
//     border-bottom-right-radius: 50% !important;
//     border-top-right-radius: 50% !important;
//     right: 6px !important;
// }

// .rdrStartEdge {
//     border-bottom-left-radius: 50% !important;
//     border-top-left-radius: 50% !important;
//     left: 6px !important;
// }
// .rdrEndEdge, .rdrInRange, .rdrSelected, .rdrStartEdge {
//     bottom: 0px !important;
//     top: 0px !important;
// }

.MuiButtonBase-root.MuiPickersDay-day.MuiPickersDateRangeDay-day.MuiPickersDateRangeDay-dayOutsideRangeInterval.MuiPickersDay-daySelected,
.MuiButtonBase-root.MuiPickersDay-day.MuiPickersDateRangeDay-day.MuiPickersDay-daySelected {
  background-color: #00ab55 !important;
}

.MuiPickersDateRangeDay-rangeIntervalDay.MuiPickersDateRangeDay-rangeIntervalDayHighlightStart.MuiPickersDateRangeDay-rangeIntervalDayHighlight,
.MuiPickersDateRangeDay-rangeIntervalDay.MuiPickersDateRangeDay-rangeIntervalDayHighlight {
  background-color: rgba(0, 171, 85, 0.4) !important;
}

.error-message {
  color: rgb(255, 24, 24);
  line-height: 1.5;
  font-size: 0.75rem;
  font-family: 'Public Sans', sans-serif;
  font-weight: 400;
  text-align: left;
  margin-top: 0 !important;
  padding-left: 38px;
}

.profile_error_message {
  padding-left: 0;
  text-align: center;
}

.required-email-error {
  text-align: left !important;
}

.addmom_table {
  td,
  tr {
    border: 1px solid rgba(241, 243, 244, 1);
  }

  .MuiInputBase-root.MuiInput-root:before,
  .MuiInputBase-root.MuiInput-root:after,
  .MuiInputBase-root-MuiInput-root:hover:not(.Mui-disabled):before {
    border-bottom: none !important;
  }

  .auto_select_box {
    border: none !important;
  }
}

.MuiTableCell-root:first-of-type,
.remove-shadow {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  box-shadow: none !important;
}

.MuiTableCell-root:last-of-type {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  box-shadow: none !important;
}

@media (min-width: 900px) and (max-width: 1350px) {
  .spend-time-text {
    // text-overflow: initial !important;
    // white-space: normal !important;
    // word-break: auto-phrase !important;
  }

  .version-text {
    text-overflow: ellipsis;
    white-space: nowrap;
    word-break: break-all;
  }
}

@media only screen and (max-width: 600px) {
  .btnwidth {
    width: 100%;
  }
  .mom_margin {
    padding: 0 20px;
  }

  .fc .fc-toolbar {
    display: block;
  }

  .task-card-section .task-card {
    width: 90%;
    margin: 5%;
  }

  .report-date-picker {
    .range-date-picker {
      margin-left: 10px;
    }
  }

  .discription_text {
    p {
      word-break: initial !important;
    }
  }

  .fc {
    .fc-header-toolbar {
      display: flex;
      flex-direction: column;
      gap: 20px;
      margin-top: 12px;
    }
  }
}

.pop-editor {
  .ql-tooltip,
  .ql-editing {
    left: 0 !important;
  }
}

@media screen and (min-width: 250px) and (max-width: 1440px) {
  .min-width-table {
    min-width: 300px;
  }
}

@media screen and (min-width: 250px) and (max-width: 1440px) {
  .min-width-table-log {
    min-width: 300px;
  }
}

@media screen and (min-width: 200px) and (max-width: 1250px) {
  .simplebar-track,
  .simplebar-horizontal {
    display: none;
  }
}

.dateColorRed {
  color: red !important;
}
.checkboxColorRed {
  color: red !important;
}

.dateColorgreen {
  color: green !important;
}
.checkboxColorgreen {
  color: green !important;
}
.csv-pdf-section {
  display: flex !important;
  .MuiButtonBase-root {
    min-width: auto;
  }
  .MuiBox-root {
    // margin-right: 10px;
    display: inline-block;
    img {
      cursor: pointer;
    }
  }
}

.centeredModal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
