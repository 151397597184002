.pop-up-flex-box{
    display: flex;
    align-items: end;
    gap: 8px;
}

@media screen and (max-width: 370px) {
    .pop-up-flex-box{
        display: flex;
        flex-direction: column;
        align-items: baseline;
    }
}